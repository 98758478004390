
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































.panel-outer {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: rgba($c-black, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s $ease-in-quad 0.4s;

  &.is-visible {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.35s $ease-out-quad;
  }
}

.panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  max-width: 72rem;
  height: 100vh;

  ::v-deep {
    .contact-form__success {
      text-align: left;
    }
  }
}

.panel-inner {
  @include fluid(
    padding-top,
    (
      s: 110px,
      xl: 130px,
    )
  );
  @include fluid(
    padding-left,
    (
      s: 34px,
      xl: 74px,
    )
  );
  @include fluid(
    padding-right,
    (
      s: 34px,
      xl: 74px,
    )
  );

  overflow: auto;
  height: 100%;
  background-color: $c-white;
}

.panel__hero {
  @include fluid(
    margin-bottom,
    (
      s: 72px,
      xl: 64px,
    )
  );
}

.panel__hero__headline {
  @extend %tag;

  display: inline-flex;
  margin-bottom: 2rem;
}

.panel__hero__title + .panel__hero__text {
  @include fluid(
    margin-top,
    (
      s: 12px,
      xl: 16px,
    )
  );
}

.panel__hero__text {
  color: $c-black-80;
}

.close {
  position: absolute;
  z-index: 1;
  top: 1rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 1rem;

  svg {
    fill: $c-black;
  }
}

// Transitions
.panel-enter-active {
  transition: transform 0.25s ease-out;
}

.panel-leave-active {
  transition: transform 0.15s ease-in;
}

.panel-leave,
.panel-enter-to {
  transform: translateX(0);
}

.panel-enter,
.panel-leave-to {
  transform: translateX(100%);
}
