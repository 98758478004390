
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































.solution-single {
  ::v-deep {
    p + h5 {
      margin-top: 5rem;
    }

    p {
      @include mq(m) {
        font-size: 1.4rem;
        line-height: 2.6rem;

        &.lead {
          font-size: 2.2rem;
          line-height: 4.2rem;
        }
      }
    }
  }
}

.solution-single__content {
  max-width: 72rem;
  margin: auto;

  @include mq($until: m) {
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }
}

.solution-single__content + .solution-single__duo {
  @include fluid(
    margin-top,
    (
      s: 100px,
      xl: 200px,
    )
  );
}

// Transitions

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(40px);
}
