
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































.sticky-bar {
  position: fixed;
  z-index: 5;
  bottom: 4rem;
  left: 0;
  width: 100%;
  pointer-events: none;

  ::v-deep {
    [class*='action--'] {
      background-color: $c-yellow;

      .action__label {
        color: $c-white;
      }

      svg {
        stroke: $c-white;
      }
    }
  }
}

.sticky-bar-wrapper {
  display: flex;
  justify-content: flex-end;
}

.sticky-bar__btn {
  @extend %shadow;

  position: relative;
  padding: 1.8rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;

  color: white;
  background-color: $c-yellow;
  border-radius: 1rem;
  pointer-events: all;

  svg {
    fill: white;
  }

  .sticky-bar__btn__label {
    @extend %fw-semi-bold;

    overflow: hidden;
    max-width: 0;
    white-space: nowrap;
    font-size: 1.3rem;
    transition: max-width 0.8s $custom-animation;

    span {
      padding-left: 1.5rem;
      padding-right: 1rem;
    }
  }

  &:hover {
    .sticky-bar__btn__label {
      max-width: 20rem;
    }
  }
}
